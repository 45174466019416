import {questionsMeta, structure} from "../structure";
import utils from "../utils";
import stateManager from "../stateManager";

export const task_4_1 = function(currentState){
    const $page = $('.page-question');
    const $buttonSkip = $page.find('.button.next');

    let meta = questionsMeta[currentState];
    const variant = structure.variant[4];
    const title = meta.isCommonTitle ? utils.formatTitle(meta.title[1]) : utils.formatTitle(meta.variants[variant[0]].title[1]);

    let currentSlide = 0;
    let answer = [];
    let answerStartTime = 0;
    let allowToFinish = true;

    const optionsCount = 11;
    const maxSelectedCount = 1;

    const processAnswer = function (){

        let $options = $page.find('#answer .subtask').removeClass('active').eq(currentSlide).addClass('active').hide().fadeIn(400);
        $page.find('.pagination span').eq(currentSlide).addClass('active');
        $options.find('.buttons .wrapper').empty();
        // $('<img src="/assets/benton/benton_' + (currentSlide+1) + '.svg">').appendTo($page.find('.subject').empty());
        const postfix = utils.isMobile() ? 'm' : '';
        $('#_image' + (variant[currentSlide]) + postfix).appendTo($page.find('.subject').empty());

        let optionsSelected = 0;

        for(let i = 1; i <= optionsCount; i++){
            $('<div>', {
                class: 'button option',
                text: i
            }).appendTo($options.find('.buttons .wrapper')).on('click', function(){

                if(optionsSelected < maxSelectedCount) {
                    $(this).addClass('active');
                    answer.push($(this).text());
                    optionsSelected++;

                    if (optionsSelected === maxSelectedCount) {

                        if (currentSlide !== (meta.subcount - 1)) {
                            currentSlide++;
                            $options.fadeOut(400, () => processAnswer());
                        } else {
                            finish(answer);
                        }
                    }
                }
            });
        }
    }

    $buttonSkip.on('click', function(){
        finish(answer);
    });

    const finish = function (answer){
        if(allowToFinish) {
            allowToFinish = false;
            $page.find('.footer .button').removeClass('active');
            $page.find('.answer .success').addClass('active');
            utils.saveAnswer(answer, answerStartTime, stateManager.goToNextState, 1500);
        }
    }

    const startTask = () => {
        answerStartTime = Date.now();
        processAnswer();
        $page.find('.general .title').fadeOut(200, () => $page.find('.general .title').html(title).fadeIn(200));
        $page.find('.footer .button').addClass('active');
        $page.find('.question').addClass('active');
        $('#answer').animate({height: window.questionHeight}, 400, function(){
            $(this).height('auto');
        });
    };

    startTask();
};