import utils from "../utils";
import _ from "lodash";
import stateManager from "../stateManager";
import {questionsMeta, structure} from "../structure";

export const task_5_1 = function(currentState, stt){
    const $page = $('.page-question');
    const $buttonSkip = $page.find('.button.next');

    let meta = questionsMeta[currentState];
    const variant = structure.variant[5][0];
    const title = meta.isCommonTitle ? utils.formatTitle(meta.title[1]) : utils.formatTitle(meta.variants[variant].title[1]);

    let finalWords = [];
    let newWords = [];
    let filledWordsCount = 0;
    let wordsToFill = 20;
    let timerTime = meta.time;
    let timerIsUp = false;
    let answer = [];
    let answerStartTime = 0;
    let allowToFinish = true;

    const timerEnd = function (){
        //console.log('timer is up');
        timerIsUp = true;

        finish(newWords.slice(0, wordsToFill));
    }

    let timer = utils.setTimer(timerTime, $page.find('.timer'), timerEnd);

    const processSpeech = function(words, text, numbers, isFinal){
        // console.log(words);
        // console.log(text);
        // console.log(numbers);
        // console.log(isFinal);

        if(!timerIsUp) {
            let lastFinalWords = finalWords.slice();
            let tempWords = _.filter(text.split(' '), function (n) {
                let firstLetter = Array.from(n)[0];
                if (firstLetter === meta.variants[variant].subject || firstLetter === meta.variants[variant].subject.toUpperCase()) {
                    return n;
                }
            });

            newWords = _.union(lastFinalWords, tempWords);

            answer = newWords;

            _.each(newWords, function (word, key) {
                if (key < wordsToFill) {
                    filledWordsCount = Math.max(filledWordsCount, key + 1);
                    $page.find('.task .ph-wrapper').eq(key).find('.placeholder').addClass('filled').find('span').text(word);
                }
            });

            if (isFinal) {
                finalWords = newWords;
                if (filledWordsCount === wordsToFill) {
                    finish(finalWords.slice(0, wordsToFill));
                }
            }
        }
    };

    $buttonSkip.on('click', function(){
        finish(answer);
    });

    const finish = function (answer){
        if(allowToFinish) {
            allowToFinish = false;
            stt.finishRecording();
            clearInterval(timer);
            $page.find('.record').removeClass('active');
            $page.find('.footer .button').removeClass('active');
            $page.find('.answer .success').addClass('active');
            utils.saveAnswer(answer, answerStartTime, stateManager.goToNextState, 1500);
        }
    }

    const startTask = async () => {
        await stt.startRecording(processSpeech);
        answerStartTime = Date.now();
        $page.find('.general .title').fadeOut(200, () => $page.find('.general .title').html(title).fadeIn(200));
        $page.find('.morph').addClass('active');
        $page.find('.record').addClass('active');
        $page.find('.footer .button').addClass('active');
        $page.find('.question').addClass('active');
        $('#answer').animate({height: window.questionHeight}, 400, function(){
            $(this).height('auto');
        });
    };

    void startTask();

};