import {questionsMeta, structure} from "../structure";
import utils from "../utils";
import stateManager from "../stateManager";
import stt from "../stt";

export const task_2_2 = function(currentState){
    const $page = $('.page-question');
    const $buttonSkip = $page.find('.button.next');

    let meta = questionsMeta[currentState];
    const variant = structure.variant[2][0];
    const title = meta.isCommonTitle ? utils.formatTitle(meta.title[1]) : utils.formatTitle(meta.variants[variant].title[1]);

    let currentSlide = 0;
    let currentDigit = 1;
    let answer = [];
    let answerStartTime = 0;
    let allowToFinish = true;

    const gridSize = 25;

    const generateGrid = function (){
        //console.log('generating new grid');

        let $grid = $page.find('#answer .subtask').removeClass('active').eq(currentSlide).empty().addClass('active').hide().fadeIn(600);
        let gridValues = _.shuffle(Array.from(Array(gridSize+1).keys()).slice(1, gridSize+1));

        currentDigit = 1;

        // $page.find('.subtitle span').eq(1).text(currentDigit);
        $page.find('.pagination span').eq(currentSlide).addClass('active');

        for(let i = 1; i <= gridSize; i++){
            $('<span>', {
                id: 'grid_'+currentSlide+'_'+i,
                class: 'grid-cell',
                text: gridValues[i-1]
            }).appendTo($grid).on('click', function(){
                if(Number($(this).text()) === currentDigit){
                    if(currentDigit !== gridSize){
                        currentDigit++;
                        // $page.find('.subtitle span').eq(1).text(currentDigit);
                    }else{
                        //show next slide or finish the questions
                        const endDate   = new Date();
                        const timeLapse = (endDate.getTime() - startDate.getTime()) / 1000;
                        answer.push(Math.round(timeLapse * 100) / 100);

                        if(currentSlide !== 2){
                            currentSlide++;
                            $grid.fadeOut(600, () => generateGrid());
                        }else{
                            finish(answer);
                        }
                    }
                    $(this).addClass('correct').on(
                        "webkitAnimationEnd oanimationend msAnimationEnd animationend",
                        function() {
                            $(this).removeClass("correct");
                        }
                    );
                }else{
                    $(this).addClass('wrong').on(
                        "webkitAnimationEnd oanimationend msAnimationEnd animationend",
                        function() {
                            $(this).removeClass("wrong");
                        }
                    );
                }
            });
        }
        const startDate = new Date();
    }

    $buttonSkip.on('click', function(){
        finish(answer);
    });

    const finish = function (answer){
        if(allowToFinish) {
            allowToFinish = false;
            $page.find('.footer .button').removeClass('active');
            $page.find('.answer .success').addClass('active');
            utils.saveAnswer(answer, answerStartTime, stateManager.goToNextState, 1500);
        }
    }

    const startTask = () => {
        generateGrid();
        answerStartTime = Date.now();
        $page.find('.general .title').fadeOut(200, () => $page.find('.general .title').html(title).fadeIn(200));
        $page.find('.footer .button').addClass('active');
        $page.find('.question').addClass('active');
        $('#answer').animate({height: window.questionHeight}, 400, function(){
            $(this).height('auto');
        });
    };

    startTask();
};