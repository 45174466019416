import './scss/app.sass';


window.$ = require('jquery');
import Navigo from 'navigo';

import stateManager from './stateManager'
import onetouch from './onetouch';
import questions from './questions';
import terms from './terms';
import results from './results';
import pdf from './pdf';
import exercise from './exercise';
import contact from './contact';


window.addEventListener("load", async () => {
    const router = new Navigo("/");
    const pagesDir = '/pages/';

    let staticRoutes = [
        'onetouch',
        'template',
        'terms',
        'privacy',
        'contact',
        'auth'
    ];

    let privateRoutes = [
        'question',
        'results'
    ];

    await stateManager.init(router, staticRoutes, privateRoutes);

    let lastPage = '';
    let render = function (page, script = false, params = {}) {
        fetch(pagesDir + page + '.html')
            .then(response => response.text())
            .then(text => {
                let isNewApp = false;
                if(lastPage !== page) {
                    $('#content').html(text);
                    isNewApp = true;
                }
                if (script) {
                    script(params.data, isNewApp, stateManager);
                }

                lastPage = page;

            });
    };

    router
        .on("/", function () {
            render('loading');
        })
        .on("/auth", function () {
            render('privacy', terms);
        })
        .on("/onetouch/:uuid", function (params) {
            render('loading', onetouch, params);
        })
        // .on("/contact/:uuid", function (params) {
        //     render('contact', contact, params);
        // })
        .on("/terms", function () {
            render('terms', terms);
        })
        .on("/privacy", function () {
            render('privacy', terms);
        })
        .on("/question/:section/:question", function (params) {
            render('question', questions, params);
        })
        .on("/results", function () {
            render('results', results);
        })
        .on("/template/pdf-results/:uuid", function (params) {
            render('pdf', pdf, params);
        })
        .on("/template/pdf-exercise/:uuid", function (params) {
            render('exercise', exercise, params);
        })
        .notFound(() => {
            render('404');
        })
        .resolve();
});



