import _ from "lodash";
import stateManager from "../stateManager";
import utils from "../utils";
import {questionsMeta, structure} from "../structure";

export const task_1_1 = function(currentState, stt){

    const $page = $('.page-question');
    const $buttonSkip = $page.find('.button.next');

    const meta = questionsMeta[currentState];
    const variant = structure.variant[0][0];
    const title = meta.isCommonTitle ? utils.formatTitle(meta.title[1]) : utils.formatTitle(meta.variants[variant].title[1]);

    let finalWords = [];
    let filledWordsCount = 0;
    let answer = [];

    let answerStartTime = 0;
    let allowToFinish = true;

    let correctAnswers = [
        ["рамка", "стиль", "тупик", "букет", "склон", "игрок", "топор", "шкаф", "живот", "суть"],
        ["дом", "лес", "кот", "стол", "звон", "ночь", "игла", "пирог", "брат", "крест"],
        ["мяч", "крик", "сон", "тень", "флаг", "свет", "игра", "топор", "мост", "сосна"],
        ["дым", "сон", "шар", "пух", "звон", "куст", "час", "лёд", "ночь", "пень"],
        ["лес", "хлеб", "стул", "брат", "конь", "гриб", "мед", "дом", "мяч", "куст"],
        ["число", "хор", "камень", "гриб", "кино", "зонт", "море", "шмель", "лампа", "рысь"],
        ["арбуз", "кисть", "море", "кот", "река", "мед", "кирпич", "жук", "король", "день"]
    ];

    const processSpeech = function(words, text, numbers, isFinal){

        text = _.intersection(text.split(' '), correctAnswers[variant]);
        let tempWords = text;

        let lastFinalWords = finalWords.slice();

        let newWords = _.union(lastFinalWords, tempWords);

        answer = newWords;

        _.each(newWords, function (word, key) {
            if(key < 10) {
                filledWordsCount = Math.max(filledWordsCount, key+1);
                $page.find('.task .ph-wrapper').eq(key).find('.placeholder span').text(word).addClass('filled');
            }
        });

        if(isFinal){
            finalWords = newWords;
            if(filledWordsCount === 10){
                finish(newWords.slice(0, 10));
            }
        }
    };

    $buttonSkip.on('click', function(){
        finish(answer);
    });

    const finish = function (answer){
        if(allowToFinish) {
            allowToFinish = false;
            stt.finishRecording();
            $page.find('.question').addClass('done');
            $page.find('.record').removeClass('active');
            $page.find('.footer .button').removeClass('active');
            $page.find('.answer .success').addClass('active');
            utils.saveAnswer(answer, answerStartTime, stateManager.goToNextState, 1500);
        }
    };

    const startTask = async () => {
        await stt.startRecording(processSpeech);
        answerStartTime = Date.now();
        $page.find('.general .title').fadeOut(200, () => $page.find('.general .title').html(title).fadeIn(200));
        $page.find('.morph').addClass('active');
        $page.find('.record').addClass('active');
        $page.find('.footer .button').addClass('active');
        $page.find('.question').addClass('active');
        $('#answer').animate({height: window.questionHeight}, 400, function(){
            $(this).height('auto');
        });

    };

    void startTask();
};