export let structure = {
    questions: [
        'question/1/1',
        'question/2/1',
        'question/2/2',
        'question/3/1',
        'question/4/1',
        'question/5/1',
        'question/6/1',
        'question/7/1',
        'question/8/1',
        'question/9/1',
        // 'contacts',
        'results'
    ]
};

export const questionsMeta = {
    'question/1/1': {
        subcount: false,
        isCommonTitle: true,
        isCommonAudio: false,
        title: [
            'Сейчас я&nbsp;буду называть вам слова. Постарайтесь их&nbsp;запомнить',
            'Назовите слова, которые вы&nbsp;запомнили'
        ],
        variants: [
            {
                audio: [
                    'D11_intro.mp3',
                    'D11_1.mp3',
                    'D11_outro.mp3',
                ]
            },
            {
                audio: [
                    'D11_intro.mp3',
                    'D11_2.mp3',
                    'D11_outro.mp3',
                ]
            },
            {
                audio: [
                    'D11_intro.mp3',
                    'D11_3.mp3',
                    'D11_outro.mp3',
                ]
            },
            {
                audio: [
                    'D11_intro.mp3',
                    'D11_4.mp3',
                    'D11_outro.mp3',
                ]
            },
            {
                audio: [
                    'D11_intro.mp3',
                    'D11_5.mp3',
                    'D11_outro.mp3',
                ]
            },
            {
                audio: [
                    'D11_intro.mp3',
                    'D11_6.mp3',
                    'D11_outro.mp3',
                ]
            },
            {
                audio: [
                    'D11_intro.mp3',
                    'D11_7.mp3',
                    'D11_outro.mp3',
                ]
            },
        ],
        time: 40,
        category: 'Память'

    },
    'question/2/1': {
        subcount: 5,
        isCommonTitle: false,
        isCommonAudio: false,
        variants: [
            {
                audio: 'D21_1.mp3',
                title: [
                    'Последовательно вычитайте {100 - 7} пять раз подряд. Ваша задача назвать все пять чисел, которые у&nbsp;вас получились',
                    'Вычтите {7} из {100}',
                    'Вычтите {7} из получившегося числа',
                ],
            },
            {
                audio: 'D21_2.mp3',
                title: [
                    'Последовательно вычитайте {62 - 7} пять раз подряд. Ваша задача назвать все пять чисел, которые у&nbsp;вас получились',
                    'Вычтите {7} из {62}',
                    'Вычтите {7} из получившегося числа',
                ],
            },
            {
                audio: 'D21_3.mp3',
                title: [
                    'Последовательно вычитайте {55 - 7} пять раз подряд. Ваша задача назвать все пять чисел, которые у&nbsp;вас получились',
                    'Вычтите {7} из {55}',
                    'Вычтите {7} из получившегося числа',
                ],
            },
            {
                audio: 'D21_4.mp3',
                title: [
                    'Последовательно вычитайте {48 - 7} пять раз подряд. Ваша задача назвать все пять чисел, которые у&nbsp;вас получились',
                    'Вычтите {7} из {48}',
                    'Вычтите {7} из получившегося числа',
                ],
            },
            {
                audio: 'D21_5.mp3',
                title: [
                    'Последовательно вычитайте {95 - 7} пять раз подряд. Ваша задача назвать все пять чисел, которые у&nbsp;вас получились',
                    'Вычтите {7} из {95}',
                    'Вычтите {7} из получившегося числа',
                ],
            },
            {
                audio: 'D21_6.mp3',
                title: [
                    'Последовательно вычитайте {54 - 7} пять раз подряд. Ваша задача назвать все пять чисел, которые у&nbsp;вас получились',
                    'Вычтите {7} из {54}',
                    'Вычтите {7} из получившегося числа',
                ],
            },
            {
                audio: 'D21_7.mp3',
                title: [
                    'Последовательно вычитайте {81 - 7} пять раз подряд. Ваша задача назвать все пять чисел, которые у&nbsp;вас получились',
                    'Вычтите {7} из {81}',
                    'Вычтите {7} из получившегося числа',
                ],
            }
        ],
        time: 7,
        category: 'Внимание'
    },
    'question/2/2': {
        subcount: 3,
        isCommonTitle: true,
        isCommonAudio: true,
        audio: 'D22_1.mp3',
        title: [
            'Перед вами будет представлена таблица, на&nbsp;которой расположены цифры от&nbsp;1&nbsp;до&nbsp;25. Ваша задача нажать на&nbsp;каждое число в&nbsp;порядке возрастания',
            'Нажмите на&nbsp;цифры от&nbsp;1&nbsp;до&nbsp;25&nbsp;в порядке возрастания'
        ],
        time: 180,
        category: 'Концентрация',
        dict: {
            'nextDigit': 'Следующая цифра:'
        }
    },
    'question/3/1': {
        subcount: 6,
        isCommonTitle: true,
        isCommonAudio: true,
        audio: 'D31_1.mp3',
        title: [
            'В&nbsp;этом задании вам будет дано определение и&nbsp;набор из&nbsp;пяти слов. Ваша задача выбрать 2&nbsp;слова из&nbsp;предложенных, которые наиболее точно характеризуют заданное определение',
            'Выберите 2&nbsp;слова, наиболее соответствующих понятию'
        ],
        variants: [
            {
                subject: 'Игра',
                options: ['карты', 'игроки', 'штрафы', 'наказания', 'правила'],
            },
            {
                subject: 'Спорт',
                options: ['медаль', 'оркестр', 'состязание', 'победа', 'стадион'],
            },
            {
                subject: 'Город',
                options: ['автомобилист', 'здание', 'толпа', 'улица', 'велосипед'],
            },
            {
                subject: 'Куб',
                options: ['углы', 'чертеж', 'сторона', 'камень', 'дерево'],
            },
            {
                subject: 'Деление',
                options: ['класс', 'делимое', 'карандаш', 'делитель', 'бумага'],
            },
            {
                subject: 'Кольцо',
                options: ['диаметр', 'алмаз', 'проба', 'округлость', 'печать'],
            },
            {
                subject: 'Сад',
                options: ["растения", "садовник", "собака", "забор", "земля"],
            },
            {
                subject: 'Сарай',
                options: ["сеновал", "лошади", "крыша", "скот", "стены"]
            },
            {
                subject: 'Река',
                options: ["берег", "рыба", "рыболов", "тина", "вода"]
            },
            {
                subject: 'Чтение',
                options: ["глаза", "книга", "картинка", "печать", "слово"]
            },
            {
                subject: 'Газета',
                options: ["правда", "приложения", "телеграммы", "бумага", "редактор"]
            },
            {
                subject: 'Книга',
                options: ["рисунки", "рассказ", "бумага", "оглавление", "текст"]
            },
            {
                subject: 'Пение',
                options: ["звон", "искусство", "голос", "аплодисменты", "мелодия"]
            },
            {
                subject: 'Библиотека',
                options: ["столы", "книги", "читальный зал", "гардероб", "читатели"]
            },
            {
                subject: 'Лес',
                options: ["почва", "грибы", "охотник", "дерево", "волк"]
            },
            {
                subject: 'Любовь',
                options: ["розы", "чувства", "человек", "свидание", "свадьба"]
            },
            {
                subject: 'Школа',
                options: ["учитель", "ученики", "парта", "книги", "тетради"]
            },
        ],
        time: 60,
        category: 'Мышление'
    },
    'question/4/1': {
        subcount: 8,
        isCommonTitle: true,
        isCommonAudio: true,
        audio: 'D41_1.mp3',
        title: [
            'Перед вами представлено два изображения. Вам необходимо выбрать номер элемента на&nbsp;картинке {Б}, который соответствует элементу, изображенному на&nbsp;картинке {А}',
            'Выберите вариант с&nbsp;картинки {Б}, который совпадает с&nbsp;картинкой {А}'
        ],
        time: 60,
        category: 'Пространственная ориентация'
    },
    'question/5/1': {
        subcount: false,
        isCommonTitle: false,
        isCommonAudio: false,
        variants: [
            {
                audio: 'D51_1.mp3',
                title: [
                    'Назовите максимальное количество слов, начинающихся на&nbsp;букву {С}, за&nbsp;исключением имен собственных, чисел или однокоренных слов',
                    'Назовите максимальное количество слов, начинающихся на&nbsp;букву {С}'
                ],
                subject: 'с'
            },
            {
                audio: 'D51_2.mp3',
                title: [
                    'Назовите максимальное количество слов, начинающихся на&nbsp;букву {К}, за&nbsp;исключением имен собственных, чисел или однокоренных слов',
                    'Назовите максимальное количество слов, начинающихся на&nbsp;букву {К}'
                ],
                subject: 'к'
            },
            {
                audio: 'D51_3.mp3',
                title: [
                    'Назовите максимальное количество слов, начинающихся на&nbsp;букву {П}, за&nbsp;исключением имен собственных, чисел или однокоренных слов',
                    'Назовите максимальное количество слов, начинающихся на&nbsp;букву {П}'
                ],
                subject: 'п'
            },
            {
                audio: 'D51_4.mp3',
                title: [
                    'Назовите максимальное количество слов, начинающихся на&nbsp;букву {Р}, за&nbsp;исключением имен собственных, чисел или однокоренных слов',
                    'Назовите максимальное количество слов, начинающихся на&nbsp;букву {Р}'
                ],
                subject: 'р'
            },
            {
                audio: 'D51_5.mp3',
                title: [
                    'Назовите максимальное количество слов, начинающихся на&nbsp;букву {Б}, за&nbsp;исключением имен собственных, чисел или однокоренных слов',
                    'Назовите максимальное количество слов, начинающихся на&nbsp;букву {Б}'
                ],
                subject: 'б'
            },
            {
                audio: 'D51_6.mp3',
                title: [
                    'Назовите максимальное количество слов, начинающихся на&nbsp;букву {М}, за&nbsp;исключением имен собственных, чисел или однокоренных слов',
                    'Назовите максимальное количество слов, начинающихся на&nbsp;букву {М}'
                ],
                subject: 'м'
            },
            {
                audio: 'D51_7.mp3',
                title: [
                    'Назовите максимальное количество слов, начинающихся на&nbsp;букву {Л}, за&nbsp;исключением имен собственных, чисел или однокоренных слов',
                    'Назовите максимальное количество слов, начинающихся на&nbsp;букву {Л}'
                ],
                subject: 'л'
            },
        ],
        time: 60,
        category: 'Речь'
    },
    'question/6/1': {
        subcount: false,
        isCommonTitle: false,
        isCommonAudio: false,
        variants: [
            {
                audio: 'D61_1.mp3',
                audioMobile: 'D61_1m.mp3',
                audioLetters: 'D61_1l.mp3',
                title: [
                    'Сейчас вы&nbsp;услышите ряд букв. Каждый раз, когда слышите букву {А}, нажимайте пробел. Если слышите другую букву, ничего нажимать не&nbsp;нужно',
                    'Нажимайте на&nbsp;пробел каждый раз, как слышите букву {А}'
                ],
                titleMobile: [
                    'Сейчас вы&nbsp;услышите ряд букв. Каждый раз, когда слышите букву {А}, нажимайте на&nbsp;синюю кнопку. Если слышите другую букву, ничего нажимать не&nbsp;нужно',
                    'Нажимайте на&nbsp;синюю кнопку каждый раз, как слышите букву {А}'
                ],
                subject: 'А',
                options: [
                    [0, 900],
                    [901, 1054],
                    [1935, 804],
                    [2758, 1147],
                    [3901, 933],
                    [4822, 940],
                    [5762, 878],
                    [6662, 817],
                    [7500, 1083],
                    [8602, 817],
                    [9434, 840],
                    [10275, 1000],
                    [11270, 860],
                    [12120, 900],
                    [13030, 770],
                    [13790, 910],
                    [14680, 860],
                    [15550, 1050],
                    [16580, 890],
                    [17470, 918],
                    [18395, 814],
                    [19220, 1010],
                    [20250, 827],
                    [21071, 836],
                    [21900, 708],
                    [22616, 956],
                    [23572, 827],
                ],
                letterPositions: [2,6,7,12,14,18,19,20,22,26],
            },
            {
                audio: 'D61_1.mp3',
                audioMobile: 'D61_1m.mp3',
                audioLetters: 'D61_2l.mp3',
                title: [
                    'Сейчас вы&nbsp;услышите ряд букв. Каждый раз, когда слышите букву {А}, нажимайте пробел. Если слышите другую букву, ничего нажимать не&nbsp;нужно',
                    'Нажимайте на&nbsp;пробел каждый раз, как слышите букву {А}'
                ],
                titleMobile: [
                    'Сейчас вы&nbsp;услышите ряд букв. Каждый раз, когда слышите букву {А}, нажимайте на&nbsp;синюю кнопку. Если слышите другую букву, ничего нажимать не&nbsp;нужно',
                    'Нажимайте на&nbsp;синюю кнопку каждый раз, как слышите букву {А}'
                ],
                subject: 'А',
                options: [
                    [0, 1236],
                    [1229, 1283],
                    [2517, 953],
                    [3468, 1200],
                    [4662, 1062],
                    [5725, 1086],
                    [6812, 1082],
                    [7895, 1104],
                    [9001, 1072],
                    [10074, 989],
                    [11065, 1198],
                    [12267, 1057],
                    [13329, 1039],
                    [14371, 968],
                    [15341, 1027],
                    [16370, 1253],
                    [17625, 1033],
                    [18660, 1242],
                    [19904, 1150],
                    [21062, 1184],
                    [22250, 1059],
                    [23312, 1239],
                    [24554, 1038],
                    [25595, 1210],
                    [26808, 1119],
                    [27932, 1276],
                    [29213, 1200],
                ],
                letterPositions: [2,6,7,12,14,18,19,20,22,26],
            },
            {
                audio: 'D61_3.mp3',
                audioMobile: 'D61_3m.mp3',
                audioLetters: 'D61_3l.mp3',
                title: [
                    'Сейчас вы&nbsp;услышите ряд букв. Каждый раз, когда слышите букву {О}, нажимайте пробел. Если слышите другую букву, ничего нажимать не&nbsp;нужно',
                    'Нажимайте на&nbsp;пробел каждый раз, как слышите букву {О}'
                ],
                titleMobile: [
                    'Сейчас вы&nbsp;услышите ряд букв. Каждый раз, когда слышите букву {О}, нажимайте на&nbsp;синюю кнопку. Если слышите другую букву, ничего нажимать не&nbsp;нужно',
                    'Нажимайте на&nbsp;синюю кнопку каждый раз, как слышите букву {О}'
                ],
                subject: 'О',
                options: [
                    [0, 1259],
                    [1281, 1237],
                    [2518, 1029],
                    [3548, 1281],
                    [4829, 1073],
                    [5902, 1073],
                    [6975, 1051],
                    [8026, 1084],
                    [9111, 1281],
                    [10381, 1116],
                    [11498, 1018],
                    [12516, 1259],
                    [13776, 1116],
                    [14893, 1149],
                    [16042, 1127],
                    [17170, 985],
                    [18156, 1149],
                    [19306, 1226],
                    [20532, 1127],
                    [21671, 1116],
                    [22799, 972],
                    [23772, 1274],
                    [25046, 1095],
                    [26141, 1124],
                    [27266, 1124],
                    [28391, 1114],
                    [29506, 1204]
                ],
                letterPositions: [2,6,7,12,14,18,19,20,22,26],
            },
            {
                audio: 'D61_4.mp3',
                audioMobile: 'D61_4m.mp3',
                audioLetters: 'D61_4l.mp3',
                title: [
                    'Сейчас вы&nbsp;услышите ряд букв. Каждый раз, когда слышите букву {У}, нажимайте пробел. Если слышите другую букву, ничего нажимать не&nbsp;нужно',
                    'Нажимайте на&nbsp;пробел каждый раз, как слышите букву {У}'
                ],
                titleMobile: [
                    'Сейчас вы&nbsp;услышите ряд букв. Каждый раз, когда слышите букву {У}, нажимайте на&nbsp;синюю кнопку. Если слышите другую букву, ничего нажимать не&nbsp;нужно',
                    'Нажимайте на&nbsp;синюю кнопку каждый раз, как слышите букву {У}'
                ],
                subject: 'У',
                options: [
                    [0, 1132],
                    [1178, 1306],
                    [2493, 1032],
                    [3535, 1315],
                    [4850, 1050],
                    [5901, 1096],
                    [6997, 1059],
                    [8057, 1023],
                    [9080, 1315],
                    [10395, 1141],
                    [11537, 1014],
                    [12551, 1324],
                    [13876, 1041],
                    [14926, 1151],
                    [16077, 1059],
                    [17137, 1087],
                    [18224, 1205],
                    [19430, 1278],
                    [20745, 1041],
                    [21787, 1041],
                    [22828, 1013],
                    [23851, 1388],
                    [25240, 1041],
                    [26281, 1087],
                    [27359, 1105],
                    [28464, 1357],
                    [29822, 1230]
                ],
                letterPositions: [2,6,7,12,14,18,19,20,22,26],
            },
            {
                audio: 'D61_5.mp3',
                audioMobile: 'D61_5m.mp3',
                audioLetters: 'D61_5l.mp3',
                title: [
                    'Сейчас вы&nbsp;услышите ряд букв. Каждый раз, когда слышите букву {И}, нажимайте пробел. Если слышите другую букву, ничего нажимать не&nbsp;нужно',
                    'Нажимайте на&nbsp;пробел каждый раз, как слышите букву {И}'
                ],
                titleMobile: [
                    'Сейчас вы&nbsp;услышите ряд букв. Каждый раз, когда слышите букву {И}, нажимайте на&nbsp;синюю кнопку. Если слышите другую букву, ничего нажимать не&nbsp;нужно',
                    'Нажимайте на&nbsp;синюю кнопку каждый раз, как слышите букву {И}'
                ],
                subject: 'И',
                options: [
                    [0, 1204],
                    [1211, 1268],
                    [2480, 966],
                    [3446, 1331],
                    [4777, 1022],
                    [5800, 1120],
                    [6921, 1099],
                    [8021, 966],
                    [8988, 1310],
                    [10298, 1148],
                    [11447, 1036],
                    [12484, 1247],
                    [13738, 1176],
                    [14915, 1134],
                    [16042, 1176],
                    [17219, 1106],
                    [18326, 1190],
                    [19496, 1324],
                    [20820, 1127],
                    [21934, 1176],
                    [23111, 1071],
                    [24176, 1380],
                    [25521, 1233],
                    [26754, 1085],
                    [27840, 1113],
                    [28947, 1318],
                    [30265, 1310]
                ],
                letterPositions: [2,6,7,12,14,18,19,20,22,26],
            },
            {
                audio: 'D61_6.mp3',
                audioMobile: 'D61_6m.mp3',
                audioLetters: 'D61_6l.mp3',
                title: [
                    'Сейчас вы&nbsp;услышите ряд букв. Каждый раз, когда слышите букву {Я}, нажимайте пробел. Если слышите другую букву, ничего нажимать не&nbsp;нужно',
                    'Нажимайте на&nbsp;пробел каждый раз, как слышите букву {Я}'
                ],
                titleMobile: [
                    'Сейчас вы&nbsp;услышите ряд букв. Каждый раз, когда слышите букву {Я}, нажимайте на&nbsp;синюю кнопку. Если слышите другую букву, ничего нажимать не&nbsp;нужно',
                    'Нажимайте на&nbsp;синюю кнопку каждый раз, как слышите букву {Я}'
                ],
                subject: 'Я',
                options: [
                    [0, 1232],
                    [1254, 1108],
                    [2363, 1181],
                    [3544, 1269],
                    [4820, 1021],
                    [5841, 955],
                    [6797, 1086],
                    [7884, 1232],
                    [9116, 1021],
                    [10137, 1079],
                    [11217, 1028],
                    [12245, 1115],
                    [13361, 1152],
                    [14513, 911],
                    [15425, 1130],
                    [16555, 940],
                    [17500, 1210],
                    [18707, 955],
                    [19662, 1115],
                    [20786, 1137],
                    [21923, 1188],
                    [23112, 1057],
                    [24170, 1115],
                    [25286, 1108],
                    [26394, 1050],
                    [27452, 984],
                    [28436, 1300]
                ],
                letterPositions: [2,6,7,12,14,18,19,20,22,26],
            },
        ],
        time: 24.4,
        category: 'Регуляция и контроль'
    },
    'question/7/1': {
        subcount: false,
        isCommonTitle: true,
        isCommonAudio: true,
        audio: 'D71_1.mp3',
        title: [
            'Перед вами представлены карточки с&nbsp;8&nbsp;цветами. Выбирайте те, к&nbsp;которым вы&nbsp;испытываете большую симпатию, по&nbsp;сравнению с&nbsp;остальными, пока все карточки не&nbsp;закончатся',
            'Выберите последовательно понравившиеся карточки с&nbsp;цветами'
        ],
        variants: [
            {
                options: [
                    ['#f24725', 3],
                    ['#353299', 1],
                    ['#974b00', 6],
                    ['#710d9d', 5],
                    ['#95a3a4', 0],
                    ['#000000', 7],
                    ['#fffe64', 4],
                    ['#01803d', 2],

                ],
            }
        ],
        time: 60,
        category: 'Психологическое состояние'
    },
    'question/8/1': {
        subcount: false,
        isCommonTitle: true,
        isCommonAudio: true,
        audio: 'D81_1.mp3',
        title: [
            'В&nbsp;начале тестирования я&nbsp;просила запомнить вас 10&nbsp;слов. Пожалуйста, назовите слова, которые вы&nbsp;помните',
            'Назовите 10&nbsp;слов, которые были в&nbsp;начале теста'
        ],
        time: 30,
        category: 'Отсроченная память',
        button: 'Закончить'
    },
    'question/9/1': {
        subcount: false,
        isCommonTitle: true,
        isCommonAudio: true,
        audio: 'D91_1.mp3',
        title: [
            'Укажите, пожалуйста, адрес вашей электронной почты, на&nbsp;который я&nbsp;отправлю вам результаты',
            'Укажите адрес вашей электронной почты'
        ],
        time: 0,
        category: 'E-mail',
        button: 'Отправить'
    }
}