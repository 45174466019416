const {structure} = require('./structure')
const _ = require('lodash');
const utils = require('./utils');
const axios = require("axios");

/////
// router manager between states
/////
let router;
let staticRoutes;
let privateRoutes;


module.exports = {
    init: async function (r, s, p) {

        router = r;
        staticRoutes = s;
        privateRoutes = p;


        const referer = window.location.search.substring(1);
        if(referer) {
            utils.setToLS('referer', referer);
        }

        if(!staticRoutes.includes((r.getCurrentLocation().url.split('/')[0]))) {

            const user = await this.checkUser();

            // console.log('step 2');
            // console.log(user);

            if(user.isValid) {
                // console.log('step 3');
                if (user.isFinished) {
                    // console.log('testFinished');
                    this.goToFinalState();
                } else {
                    let currentState = utils.getCurrentState();

                    //case: no state data, init test - make sure we are at the beginning
                    if (currentState === null) {
                        currentState = structure.questions[0];
                        utils.setCurrentState(currentState);
                        //redirect if not at the beginning
                        if (!utils.isStateEqualToCurrent()) router.navigate(currentState);
                    }
                        //case: test is finished - not allowed to open questions; redirect to results
                        // else if (currentState === _.last(structure)) {
                        //     if (!utils.isStateEqualToCurrent()) {
                        //         this.resetState();
                        //     }
                        // }
                    //case: test is in progress - make sure we are at the last unanswered question
                    else {
                        if (!utils.isStateEqualToCurrent()) router.navigate(currentState);
                    }

                }
            }
        }
    },
    initTest: async function(){
        let currentState = structure.questions[0];
        utils.setCurrentState(currentState);
        //redirect if not at the beginning
        if (!utils.isStateEqualToCurrent()) router.navigate(currentState);
    },
    checkUser: async function(){
        const self = this;
        let user = {
            isValid: false,
            isFinished: false
        };
        const uuid = utils.getFromLS('uuid');
        if(uuid !== null){
            await axios.post(process.env.API_URL + 'api/user/check', {
                uuid: uuid
            })
            .then(async function (response) {
                response = response.data;
                if(response.status){
                    user = response.data;

                    // console.log('step 1');
                    // console.log(user);
                    if(!user.isValid){
                        // console.log('1 redirect to MDINC.RU');

                        // utils.redirectToHome();

                        await self.initTest();
                    }

                    structure.variant = JSON.parse(user.variant);
                    structure.questions = user.structure;
                }else{
                    utils.showNotification(response.message);
                }

            })
            .catch(function (error) {
                console.log(error);
                console.log('error');
                utils.showNotification('Ошибка сети');
                // utils.showNotification(response.message);
            });
        }else{
            // console.log('2 redirect to MDINC.RU');
            // utils.redirectToHome();

            await self.initTest();
        }



        return user;
    },
    goToNextState: function(){
        //console.log('going to next state');
        let currentState = utils.getCurrentState();
        const currentIndex = _.indexOf(structure.questions, currentState);
        currentState = structure.questions[currentIndex+1];
        utils.setCurrentState(currentState);
        router.navigate(currentState);
    },
    goToFinalState: function(){
        //console.log('going to last state');
        let currentState = _.last(structure.questions);
        utils.setCurrentState(currentState);
        router.navigate(currentState);
    }
};